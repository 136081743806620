const HowTtsWorkIcon = () => {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 20C4.49379 20 0 15.5059 0 10C0 4.49379 4.49414 0 10 0C15.5062 0 20 4.4941 20 10C20 15.5062 15.5059 20 10 20ZM10 1.17188C5.13215 1.17188 1.17188 5.13215 1.17188 10C1.17188 14.8679 5.13215 18.8281 10 18.8281C14.8679 18.8281 18.8281 14.8679 18.8281 10C18.8281 5.13215 14.8679 1.17188 10 1.17188ZM10 12.9297C9.67641 12.9297 9.41406 12.6673 9.41406 12.3438V11.7166C9.41406 10.674 9.9752 9.70176 10.8785 9.17922C11.4209 8.86551 11.7578 8.28191 11.7578 7.65625C11.7578 6.68699 10.9693 5.89844 10 5.89844C9.03074 5.89844 8.24219 6.68699 8.24219 7.65625C8.24219 7.97984 7.97984 8.24219 7.65625 8.24219C7.33266 8.24219 7.07031 7.97984 7.07031 7.65625C7.07031 6.04082 8.38457 4.72656 10 4.72656C11.6154 4.72656 12.9297 6.04082 12.9297 7.65625C12.9297 8.69883 12.3686 9.67109 11.4653 10.1936C10.9229 10.5074 10.5859 11.091 10.5859 11.7166V12.3438C10.5859 12.6673 10.3236 12.9297 10 12.9297ZM10.5859 14.6875C10.5859 15.0111 10.3236 15.2734 10 15.2734C9.6764 15.2734 9.41406 15.0111 9.41406 14.6875C9.41406 14.3639 9.6764 14.1016 10 14.1016C10.3236 14.1016 10.5859 14.3639 10.5859 14.6875Z"
          fill="url(#paint0_linear_6290_39836)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6290_39836"
            x1="4.05913"
            y1="1.50084"
            x2="8.24951"
            y2="17.445"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2AEBEB" />
            <stop offset="0.45" stop-color="#6174FF" />
            <stop offset="0.75" stop-color="#B63CFB" />
            <stop offset="1" stop-color="#FF47E3" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default HowTtsWorkIcon;
