const ErrorToastIcon = (props: { width: number; height: number }) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 28 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3024 1.92279L26.9941 18.743C27.2073 19.245 27.3007 19.6533 27.3273 20.0774C27.3807 21.0683 27.034 22.0314 26.3542 22.7726C25.6743 23.5112 24.7544 23.9472 23.7546 24H4.23789C3.82462 23.9749 3.41136 23.8811 3.02476 23.7358C1.09175 22.9563 0.158575 20.7631 0.945109 18.8619L10.7035 1.9109C11.0367 1.31504 11.5433 0.801091 12.1699 0.470792C13.9829 -0.53464 16.2892 0.125959 17.3024 1.92279ZM15.156 13.0076C15.156 13.6418 14.6361 14.1716 13.9962 14.1716C13.3563 14.1716 12.8231 13.6418 12.8231 13.0076V9.26997C12.8231 8.63447 13.3563 8.12053 13.9962 8.12053C14.6361 8.12053 15.156 8.63447 15.156 9.26997V13.0076ZM13.9962 18.6901C13.3563 18.6901 12.8231 18.1603 12.8231 17.5275C12.8231 16.892 13.3563 16.3635 13.9962 16.3635C14.6361 16.3635 15.156 16.8801 15.156 17.5129C15.156 18.1603 14.6361 18.6901 13.9962 18.6901Z"
        fill="#D94F4F"
      />
    </svg>
  );
};

export default ErrorToastIcon;
