const Logo = ({ width = 42, height = 42, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 42 42" fill="none">
      <path
        d="M17.3298 17.2504C16.3512 18.229 15.7422 19.5852 15.7422 21.0824C15.7422 22.5795 16.3512 23.9331 17.3298 24.9143C18.311 25.8956 19.6646 26.5019 21.1617 26.5019C22.6589 26.5019 24.0124 25.8956 24.9937 24.9143C25.9749 23.9331 26.5812 22.5795 26.5812 21.0824C26.5812 19.5852 25.9749 18.2317 24.9937 17.2504C24.0151 16.2691 22.6589 15.6628 21.1617 15.6628C19.6646 15.6628 18.311 16.2691 17.3298 17.2504Z"
        fill="url(#paint0_linear_5897_30593)"
      />
      <path
        d="M29.5524 35.4635C29.1084 34.1684 28.3664 33.009 27.4091 32.065C27.4144 32.065 27.4197 32.0623 27.4224 32.0597C25.8933 30.5785 23.7606 29.5946 21.487 29.5307C21.4099 29.5307 21.3327 29.5281 21.2556 29.5281C21.1998 29.5281 21.1439 29.5281 21.0881 29.5281C21.0535 29.5281 21.0216 29.5281 20.987 29.5307C20.9232 29.5307 20.8621 29.5334 20.7982 29.5387H20.7796C20.7158 29.5414 20.652 29.5467 20.5908 29.552C20.5084 29.5573 20.4286 29.5653 20.3488 29.5733C20.285 29.5813 20.2212 29.5866 20.1547 29.5946C20.1387 29.5946 20.1254 29.5999 20.1095 29.5999C20.0404 29.6079 19.9739 29.6185 19.9047 29.6291C19.8356 29.6398 19.7638 29.6531 19.6947 29.6637C19.6388 29.6743 19.5803 29.685 19.5245 29.6956C19.4793 29.7062 19.4367 29.7142 19.3942 29.7249C19.3436 29.7355 19.2931 29.7461 19.2452 29.7594C19.0963 29.794 18.9474 29.8339 18.8011 29.8791C17.3598 30.3019 16.0675 31.0917 15.0357 32.1368C14.0916 33.0915 13.3657 34.2562 12.9375 35.5566C12.9295 35.5513 12.9216 35.5486 12.9136 35.5433C12.6424 36.4315 12.4961 37.3755 12.4961 38.3541V40.1251C15.1872 41.351 18.1337 41.9999 21.1625 41.9999C24.1914 41.9999 27.2495 41.3351 30.0258 40.0347V38.3142C30.0258 37.317 29.8636 36.3597 29.5604 35.4608C29.5604 35.4608 29.5551 35.4635 29.5524 35.4635Z"
        fill="url(#paint1_linear_5897_30593)"
      />
      <path
        d="M6.49221 9.55464C7.27935 10.3019 8.52121 10.2593 9.27112 9.47486C12.2681 6.33165 16.467 4.37445 21.1047 4.37445C25.7424 4.37445 29.7313 6.23325 32.707 9.23819C33.4622 10.0014 34.6801 10.028 35.4593 9.29138L35.7837 8.98556C36.5655 8.24098 36.6028 6.99379 35.8422 6.22528C35.8422 6.21996 35.8342 6.21464 35.8289 6.20932C31.872 2.20717 26.6439 0 21.1021 0C15.5602 0 10.3348 2.20717 6.38052 6.21198C6.29277 6.30239 6.20502 6.39015 6.11992 6.48322C5.36736 7.27036 5.39661 8.5202 6.1864 9.26744L6.48955 9.55464H6.49221Z"
        fill="url(#paint2_linear_5897_30593)"
      />
      <path
        d="M5.07494 17.2424C5.30629 16.2585 4.8037 15.2454 3.89424 14.8066C2.62844 14.1923 1.12331 14.9183 0.806862 16.2878C0.445206 17.8434 0.261719 19.4496 0.261719 21.0824C0.261719 27.5018 3.09913 33.493 8.04265 37.5138C8.49206 37.8781 9.16219 37.559 9.16219 36.982V32.5889C6.26362 29.5015 4.63616 25.4196 4.63616 21.0824C4.63616 19.7607 4.78774 18.4763 5.07494 17.2424Z"
        fill="url(#paint3_linear_5897_30593)"
      />
      <path
        d="M41.3408 16.0431C41.0084 14.6816 39.5165 13.9636 38.2561 14.5752C37.3253 15.0273 36.844 16.067 37.0913 17.0722C37.4051 18.3566 37.57 19.6995 37.57 21.0823C37.57 25.4355 35.8494 29.6584 32.8844 32.7537L32.8711 32.767V37.1521C32.8711 37.7132 33.5199 38.027 33.9587 37.6786C39.0325 33.6552 41.9444 27.6054 41.9444 21.0823C41.9444 19.3618 41.7397 17.6732 41.3408 16.0431Z"
        fill="url(#paint4_linear_5897_30593)"
      />
      <path
        d="M33.6125 21.0824C33.6125 20.2713 33.5354 19.4788 33.3892 18.7103C33.1738 17.5722 31.9505 16.9286 30.8762 17.3621C30.0438 17.6972 29.5891 18.5747 29.7513 19.4549C29.8497 19.9814 29.9002 20.5266 29.9002 21.0797C29.9002 22.4918 29.5732 23.824 28.9881 25.0074C28.693 25.6057 28.7169 26.3104 29.0785 26.8689C29.8683 28.0841 31.674 27.9592 32.3095 26.6561C33.7402 23.715 33.6099 21.085 33.6099 21.085L33.6125 21.0824Z"
        fill="url(#paint5_linear_5897_30593)"
      />
      <path
        d="M29.3321 14.594C30.2602 13.8973 30.3346 12.5198 29.473 11.7433C27.2712 9.75683 24.3513 8.54688 21.1789 8.54688C18.0064 8.54688 15.0999 9.75683 12.9007 11.7433C12.0417 12.5198 12.1189 13.8973 13.0443 14.594C13.7623 15.1365 14.7515 15.07 15.4243 14.4717C16.9613 13.1075 18.985 12.2778 21.1869 12.2778C23.3887 12.2778 25.4124 13.1075 26.9494 14.4717C27.6222 15.0673 28.6114 15.1338 29.3294 14.594H29.3321Z"
        fill="url(#paint6_linear_5897_30593)"
      />
      <path
        d="M13.2947 26.8662C13.6564 26.3077 13.6803 25.603 13.3852 25.0047C12.8001 23.8214 12.473 22.4891 12.473 21.077C12.473 20.5212 12.5236 19.9788 12.622 19.4522C12.7842 18.572 12.3294 17.6945 11.4971 17.3594C10.4228 16.926 9.19952 17.5695 8.98412 18.7076C8.83787 19.4762 8.76075 20.2686 8.76075 21.0797C8.76075 21.0797 8.63311 23.7123 10.0611 26.6508C10.694 27.9538 12.5023 28.0815 13.2921 26.8635L13.2947 26.8662Z"
        fill="url(#paint7_linear_5897_30593)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5897_30593"
          x1="34.3116"
          y1="7.93244"
          x2="7.41611"
          y2="34.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC5CA1" />
          <stop offset="1" stop-color="#8112FC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5897_30593"
          x1="42.4976"
          y1="16.1175"
          x2="15.6021"
          y2="43.0131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC5CA1" />
          <stop offset="1" stop-color="#8112FC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5897_30593"
          x1="27.5294"
          y1="1.15145"
          x2="0.633911"
          y2="28.047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC5CA1" />
          <stop offset="1" stop-color="#8112FC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5897_30593"
          x1="28.9522"
          y1="2.57409"
          x2="2.0567"
          y2="29.4696"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC5CA1" />
          <stop offset="1" stop-color="#8112FC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5897_30593"
          x1="44.3138"
          y1="17.9365"
          x2="17.4183"
          y2="44.832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC5CA1" />
          <stop offset="1" stop-color="#8112FC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5897_30593"
          x1="39.9734"
          y1="13.5966"
          x2="13.0779"
          y2="40.4921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC5CA1" />
          <stop offset="1" stop-color="#8112FC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5897_30593"
          x1="30.34"
          y1="3.95969"
          x2="3.44442"
          y2="30.8552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC5CA1" />
          <stop offset="1" stop-color="#8112FC" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5897_30593"
          x1="30.0559"
          y1="3.67763"
          x2="3.1604"
          y2="30.5758"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC5CA1" />
          <stop offset="1" stop-color="#8112FC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
