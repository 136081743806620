const BoSliderStepTwoIcon = () => {
  return (
    <>
      <svg width="43" height="43" viewBox="0 0 43 43" fill="none">
        <circle
          cx="21.2695"
          cy="21.6138"
          r="21"
          fill="url(#paint0_linear_6059_49811)"
        />
        <circle
          cx="21.2695"
          cy="21.6138"
          r="21"
          fill="url(#paint1_linear_6059_49811)"
        />
        <path
          d="M26.7862 21.3154C26.7862 21.5389 26.9854 21.7382 27.209 21.7382C27.4325 21.7382 27.6318 21.5389 27.6318 21.3154V19.076C28.2042 18.7523 28.5522 18.1798 28.5522 17.5082V15.5925C28.5522 14.5972 27.7309 13.7759 26.7356 13.7759H21.9846V12.8555C21.9846 11.9098 21.2129 11.1138 20.2429 11.1138H17.5554C16.6097 11.1138 15.8137 11.8855 15.8137 12.8555V13.7759H11.0861C10.0908 13.7759 9.26953 14.5972 9.26953 15.5925V17.5082C9.26953 18.1798 9.61748 18.7776 10.19 19.076V25.6443C10.19 26.6396 11.0113 27.4609 12.0065 27.4609H22.0583C22.2819 27.4609 22.4811 27.2617 22.4811 27.0381C22.4811 26.8146 22.2819 26.6153 22.0583 26.6153H12.0065C11.4593 26.6153 11.0365 26.1925 11.0365 25.6453V19.3995C11.9327 19.6978 13.2506 20.0468 15.0672 20.245C15.2907 20.2703 15.5152 20.0954 15.5395 19.8718C15.5648 19.6483 15.3898 19.4237 15.1663 19.3995C13.0018 19.1506 11.5584 18.7026 10.7371 18.3789C10.3638 18.2292 10.115 17.8813 10.115 17.4827V15.567C10.115 15.0198 10.5631 14.597 11.085 14.597H26.7606C27.3078 14.597 27.7306 15.0451 27.7306 15.567V17.4827C27.7306 17.8813 27.4818 18.2292 27.1086 18.3789C26.2873 18.7026 24.8439 19.1506 22.6794 19.3995C22.4558 19.4247 22.2808 19.623 22.3061 19.8718C22.3314 20.0954 22.5054 20.245 22.7289 20.245H22.7785C24.5951 20.0458 25.9141 19.6978 26.8091 19.3995L26.8111 21.3152L26.7862 21.3154ZM16.6595 12.8555C16.6595 12.3579 17.058 11.9594 17.5556 11.9594H20.2431C20.7407 11.9594 21.1393 12.3579 21.1393 12.8555V13.7759H16.6595L16.6595 12.8555Z"
          fill="white"
          stroke="white"
          stroke-width="0.3"
        />
        <path
          d="M17.8794 18.3047C17.0834 18.3047 16.436 18.952 16.436 19.748V20.5441C16.436 21.3401 17.0834 21.9874 17.8794 21.9874H19.9448C20.7408 21.9874 21.3881 21.3401 21.3881 20.5441V19.748C21.3881 18.952 20.7408 18.3047 19.9448 18.3047H17.8794ZM20.5659 19.7733V20.5693C20.5659 20.9173 20.2918 21.1914 19.9438 21.1914H17.8784C17.5305 21.1914 17.2564 20.9173 17.2564 20.5693V19.7733C17.2564 19.4253 17.5305 19.1512 17.8784 19.1512H19.9438C20.2928 19.1512 20.5659 19.4244 20.5659 19.7733Z"
          fill="white"
          stroke="white"
          stroke-width="0.3"
        />
        <path
          d="M28.1306 22.5597C25.4926 22.5597 23.3535 24.7 23.3535 27.3368C23.3535 29.9748 25.4938 32.1139 28.1306 32.1139C29.4 32.1139 30.5935 31.6162 31.515 30.7201C32.4112 29.8239 32.9088 28.6304 32.9088 27.3357C32.9079 24.6987 30.7675 22.5597 28.1306 22.5597ZM30.9171 30.1233C30.1707 30.8698 29.1754 31.2683 28.1306 31.2683C25.9661 31.2683 24.199 29.5013 24.199 27.3367C24.199 25.1721 25.966 23.4051 28.1306 23.4051C30.2952 23.4051 32.0622 25.1721 32.0622 27.3367C32.0622 28.3815 31.6636 29.3769 30.9171 30.1233Z"
          fill="white"
          stroke="white"
          stroke-width="0.3"
        />
        <path
          d="M29.7974 25.7939L27.6076 27.9837L26.4626 26.8387C26.2886 26.6648 26.0398 26.6648 25.8658 26.8387C25.6919 27.0127 25.6919 27.2615 25.8658 27.4355L27.3092 28.8789C27.384 28.9537 27.4832 29.0033 27.6076 29.0033C27.7067 29.0033 27.8311 28.9537 27.906 28.8789L30.3941 26.3907C30.5681 26.2167 30.5681 25.9679 30.3941 25.7939C30.2202 25.6442 29.9714 25.6442 29.7974 25.7939Z"
          fill="white"
          stroke="white"
          stroke-width="0.3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6059_49811"
            x1="-1.76423"
            y1="69.6138"
            x2="-67.8441"
            y2="-6.20568"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.145" stop-color="#FF97EF" />
            <stop offset="1" stop-color="#5800E8" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_6059_49811"
            x1="-103.053"
            y1="164.912"
            x2="-114.979"
            y2="5.14231"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.576997" stopColor="#5800E8" />
            <stop offset="0.961976" stopColor="#FF97EF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default BoSliderStepTwoIcon;
