const StepSecondIcon = () => {
  return (
    <>
      <svg width="42" height="43" viewBox="0 0 42 43" fill="none">
        <circle
          cx="21"
          cy="21.8635"
          r="21"
          fill="url(#paint0_linear_6059_49614)"
        />
        <g clip-path="url(#clip0_6059_49614)">
          <path
            d="M24.7045 18.4316L22.6375 18.1196L21.7015 16.2476C21.5845 15.9746 21.3115 15.8186 20.9995 15.8186C20.6875 15.8186 20.4145 15.9746 20.2975 16.2476L19.3615 18.1196L17.2945 18.4316C16.9825 18.4706 16.7485 18.6656 16.6705 18.9776C16.5925 19.2506 16.6705 19.5626 16.8655 19.7576L18.3865 21.2396L18.0355 23.3066C17.9965 23.5406 18.0745 23.7746 18.1915 23.9306C18.4255 24.2036 18.8155 24.2816 19.1665 24.1256L21.0385 23.1506L22.9105 24.1256C23.2225 24.2816 23.6515 24.2036 23.8855 23.9306C24.0415 23.7746 24.0805 23.5406 24.0415 23.3066L23.6905 21.2396L25.2115 19.7576C25.4065 19.5626 25.4845 19.2506 25.4065 18.9386C25.2505 18.6656 25.0165 18.4706 24.7045 18.4316ZM23.1055 20.6936C22.9105 20.8886 22.8325 21.1226 22.8715 21.3956L23.2225 23.4626L21.3505 22.4876C21.1165 22.3706 20.8435 22.3706 20.6095 22.4876L18.7375 23.4626L19.0885 21.3956C19.1275 21.1616 19.0495 20.8886 18.8545 20.6936L17.3335 19.2116L19.4005 18.8996C19.6735 18.8606 19.8685 18.7046 19.9855 18.4706L20.9215 16.5986L21.8575 18.4706C21.9745 18.7046 22.2085 18.8606 22.4425 18.8996L24.5095 19.2116L23.1055 20.6936Z"
            fill="white"
          />
          <path
            d="M28.8386 12.8936H13.1606C12.3416 12.8936 11.6396 13.5956 11.6396 14.4146V25.4516C11.6396 26.3096 12.3416 26.9726 13.1606 26.9726H13.3556V30.4436C13.3556 30.5996 13.4336 30.7166 13.5896 30.7946C13.7066 30.8726 13.8626 30.8336 13.9796 30.7556L18.6596 26.9726H28.8386C29.6966 26.9726 30.3596 26.2706 30.3596 25.4516V14.4146C30.3596 13.5956 29.6576 12.8936 28.8386 12.8936ZM29.5796 25.4516C29.5796 25.8806 29.2286 26.1926 28.8386 26.1926H18.5036L18.3866 26.2316C18.3866 26.2316 18.2696 26.2706 18.2696 26.3096L14.1356 29.6246V26.5826C14.1356 26.3486 13.9796 26.1926 13.7456 26.1926H13.1606C12.7316 26.1926 12.4196 25.8416 12.4196 25.4516V14.4146C12.4196 14.0246 12.7706 13.6736 13.1606 13.6736H28.8386C29.2286 13.6736 29.5796 14.0246 29.5796 14.4146V25.4516Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_6059_49614"
            x1="-103.323"
            y1="165.161"
            x2="-115.249"
            y2="5.39206"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.576997" stopColor="#5800E8" />
            <stop offset="0.961976" stopColor="#FF97EF" />
          </linearGradient>
          <clipPath id="clip0_6059_49614">
            <rect
              width="19.5"
              height="19.5"
              fill="white"
              transform="translate(11.25 12.1135)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default StepSecondIcon;
