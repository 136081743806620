const BoSliderStepOneIcon = () => {
  return (
    <>
      <svg width="42" height="43" viewBox="0 0 42 43" fill="none">
        <circle
          cx="21.0005"
          cy="21.207"
          r="21"
          fill="url(#paint0_linear_6059_49780)"
        />
        <path
          d="M25.1894 29.5281V27.83H26.8875V27.1508H25.1894V25.4526H24.5101V27.1508H22.812V27.83H24.5101V29.5281H25.1894Z"
          fill="white"
          stroke="white"
        />
        <path
          d="M20.0946 20.358C22.3422 20.358 24.1701 18.5302 24.1701 16.2825C24.1701 14.0349 22.3422 12.207 20.0946 12.207C17.8469 12.207 16.019 14.0349 16.019 16.2825C16.019 18.5302 17.8469 20.358 20.0946 20.358ZM20.0946 12.8863C21.9672 12.8863 23.4908 14.4098 23.4908 16.2825C23.4908 18.1552 21.9672 19.6788 20.0946 19.6788C18.2219 19.6788 16.6983 18.1552 16.6983 16.2825C16.6983 14.4098 18.2219 12.8863 20.0946 12.8863Z"
          fill="white"
          stroke="white"
        />
        <path
          d="M15.0005 30.2074H15.6797V27.4904C15.6797 26.3112 16.1389 25.202 16.9724 24.3679C17.8065 23.5337 18.915 23.0752 20.0949 23.0752C21.3495 23.0752 22.5436 23.6105 23.3729 24.5438L23.881 24.0928C22.9233 23.0148 21.543 22.396 20.0949 22.396C18.7343 22.396 17.4546 22.9258 16.4921 23.8876C15.5303 24.8501 15.0005 26.1298 15.0005 27.4904V30.2074Z"
          fill="white"
          stroke="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6059_49780"
            x1="-103.322"
            y1="164.505"
            x2="-115.248"
            y2="4.73557"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.576997" stopColor="#5800E8" />
            <stop offset="0.961976" stopColor="#FF97EF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default BoSliderStepOneIcon;
