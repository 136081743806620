const CongratulationIcon = (props: { width: number; height: number }) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 82 82"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 82C18.356 82 0 63.644 0 41C0 18.356 18.356 0 41 0C63.644 0 82 18.356 82 41C82 63.644 63.644 82 41 82ZM59.4295 27.2138C59.1576 26.9416 58.8347 26.7257 58.4793 26.5784C58.1238 26.4311 57.7429 26.3552 57.3581 26.3552C56.9734 26.3552 56.5925 26.4311 56.237 26.5784C55.8816 26.7257 55.5587 26.9416 55.2868 27.2138L36.6096 45.8978C36.0599 46.4466 35.315 46.7549 34.5382 46.7549C33.7615 46.7549 33.0165 46.4466 32.4669 45.8978L26.966 40.3953C26.4169 39.8459 25.672 39.5372 24.8953 39.537C24.1185 39.5369 23.3735 39.8453 22.8242 40.3944C22.2748 40.9435 21.9661 41.6884 21.966 42.4651C21.9658 43.2419 22.2742 43.9869 22.8233 44.5363L32.4669 54.1798C33.6115 55.3244 35.465 55.3244 36.6096 54.1798L59.4312 31.3582C60.5758 30.2136 60.5758 28.36 59.4312 27.2172"
        fill="#44BB49"
      />
    </svg>
  );
};

export default CongratulationIcon;
